(() => {
  const theme = Math.ceil(Math.random() * 3);
  document.querySelector("body").classList.add(`theme-${theme}`);

  // set theme color
  const color = getComputedStyle(document.documentElement).getPropertyValue(
    `--theme-${theme}-dark`
  );

  document
    .querySelector("meta[name=theme-color]")
    .setAttribute("content", color);

  console.log("Hi there! This website was created by YungPanda");
})();
